import { useEffect, useMemo } from 'react'
import { gql as contentfulGQL, useQuery } from '@apollo/client'
import { EntryFields } from 'contentful'
import { slugs } from '@/constants'
import { ContentfulClientOptions, getContentfulClient } from '@/services/ApolloClient'
import { Faq, GetFaqsForProjectQuery } from '@/types/codegen-contentful'
import { logger } from '@/utils/logging'
import { contentfulFaqLocations } from './contentfulFaqLocations'

type FaqProject = {
  faq: {
    project: {
      slug: EntryFields.Text
    }
  }
}

type FaqCollectionResponse = {
  faqCollection: {
    items: Faq[]
  }
}

export const getFaqsForProjectByLocationQuery = contentfulGQL/* GraphQL */ `
  query getFaqsForProject($project: String!, $location: String!, $locale: String, $region: String, $preview: Boolean!) {
    faqCollection(
      where: {
        OR: [{ project: { slug: $project } }, { project: { slug: "angel-studios" } }]
        AND: [{ OR: [{ regionList_exists: false }, { regionList_contains_all: [$region] }] }]
        location: $location
      }
      locale: $locale
      order: ordinal_ASC
      preview: $preview
    ) {
      items {
        answer {
          json
        }
        ordinal
        hashLink
        project {
          slug
        }
        question
      }
    }
  }
`

const getProjectFromFaqQuery = contentfulGQL/* GraphQL */ `
  query getFaq($id: String!, $preview: Boolean!) {
    faq(id: $id, preview: $preview) {
      project {
        slug
      }
    }
  }
`

const extractFaqEntries = (response: FaqCollectionResponse) => {
  return response?.faqCollection?.items
}

const extractProjectSlug = (response: FaqProject) => {
  return response?.faq?.project?.slug
}

interface GetFaqsForProjectByLocationArgs {
  location: string
  opts: ContentfulClientOptions
  project: string
  region?: string
}

export const getFaqsForProjectByLocation = async ({
  location,
  opts,
  project,
  region,
}: GetFaqsForProjectByLocationArgs) => {
  const contentfulClient = getContentfulClient({
    preview: opts.preview,
    locale: opts.locale,
  })

  const { data } = await contentfulClient.query({
    query: getFaqsForProjectByLocationQuery,
    variables: {
      location,
      preview: opts.preview,
      project,
      region,
    },
  })

  return extractFaqEntries(data)
}

export const getProjectSlugFromFaq = async (id: string, opts: ContentfulClientOptions) => {
  const contentfulClient = getContentfulClient({
    locale: opts.locale,
    preview: opts.preview,
  })

  const { data } = await contentfulClient.query({
    query: getProjectFromFaqQuery,
    variables: {
      id,
      preview: opts.preview,
    },
  })

  return extractProjectSlug(data)
}

interface UseProjectFaqsArgs {
  locale: string
  preview: boolean
  region?: string
  projectSlug?: string
  location?: string
}

export const useProjectFaqs = ({ locale, preview, region, projectSlug, location }: UseProjectFaqsArgs) => {
  const client = getContentfulClient({ locale, preview, region })

  const {
    data: faqData,
    loading: loadingProjectFaqs,
    error,
  } = useQuery<GetFaqsForProjectQuery>(getFaqsForProjectByLocationQuery, {
    client,
    variables: {
      location: location ?? contentfulFaqLocations.home,
      project: projectSlug ?? slugs.angelStudios,
      locale,
      preview,
      region,
    },
    onError: (error) => {
      logger().error('Error fetching FAQs:', { projectSlug, location, locale, region }, error)
    },
  })

  useEffect(() => {
    if (!loadingProjectFaqs && !error && (!faqData?.faqCollection?.items || faqData.faqCollection.items.length === 0)) {
      logger().warn('No FAQs returned for query:', {
        projectSlug,
        location,
        locale,
        region,
        responseReceived: !!faqData,
        hasCollection: !!faqData?.faqCollection,
      })
    }
  }, [faqData, loadingProjectFaqs, error, projectSlug, location, locale, region])

  return useMemo(() => {
    return {
      faqs: faqData?.faqCollection?.items as Faq[],
      loadingProjectFaqs,
      error,
    }
  }, [faqData?.faqCollection?.items, loadingProjectFaqs, error])
}
